@import "../../variables";

.TopNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  img {
    height: 60px;
    display: inline-block;
  }
  @media (max-width: $phone) {
    background-color: #0a1427;
  }
}

.Brand {
  width: calc(50% - 50px);
  padding: 15px 25px;
  background-color: $secondary;
  @media (max-width: $phone) {
    width: calc(50% - 50px);
  }
}

.TopNav__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  background-color: $secondary;
  height: 93px;
  padding-right: 30px;
  a {
    margin-left: 50px;
    svg {
      display: inline;
      margin-right: 5px;
    }
  }
}

@import "../../variables";

.UnAuthHero {
  background-color: $secondary;
  color: white;
  h1 {
    font-size: 3em;
    @media (max-width: $phone) {
      font-size: 1.75em;
    }
  }
  ul {
    li {
      font-size: 1.5em;
      margin: 15px 0px;
    }
  }
  // Background Image
  background-size: 600px;
  background-position: -100px center;
  background-repeat: no-repeat;
}

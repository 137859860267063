@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

/* Commented out for testing
.Brand {
  background-image: url('/assets/' + $brand);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
  height: 60px;
  width: 180px;
  display: block;
}
*/
body {
  background-color: #FFF;
  color: black; }

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900; }

.Data {
  font-family: "Share Tech Mono", sans-serif;
  font-weight: 400;
  word-break: break-all;
  margin: 0; }

.Bold {
  font-weight: 900; }

a {
  color: #007AFF;
  fill: #007AFF;
  text-decoration: none;
  -webkit-transition: all 0.15s linear;
  transition: all 0.15s linear;
  vertical-align: middle;
  cursor: pointer; }
  a svg {
    display: inline-block;
    margin: 0;
    padding: 0px;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-top: -3px; }
    a svg.IconLeft {
      margin-right: 5px; }
    a svg.IconRight {
      margin-left: 5px; }
  a:hover {
    color: #0062cc;
    fill: #0062cc; }

button,
.Button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  background-color: #007AFF;
  color: white;
  border-radius: 50px;
  padding: 15px 30px;
  max-width: 100%;
  display: block;
  margin: 15px 0px;
  font-size: 16px;
  text-align: center;
  font-weight: 900; }
  button:hover,
  .Button:hover {
    background-color: #0062cc;
    color: white;
    fill: white; }
  button:disabled, button[disabled],
  .Button:disabled,
  .Button[disabled] {
    opacity: 0.5; }
  button.Back,
  .Button.Back {
    margin: 0;
    padding-top: 20px;
    padding-left: 0px;
    margin-left: 0px;
    left: 0;
    text-align: left;
    background-color: transparent;
    color: black !important;
    border-radius: 0px;
    width: auto; }
    button.Back svg,
    .Button.Back svg {
      fill: black !important; }
    button.Back:hover,
    .Button.Back:hover {
      margin-left: -5px; }
  button svg,
  .Button svg {
    display: inline-block;
    margin: 0;
    padding: 0px;
    vertical-align: middle;
    fill: white; }
    button svg.IconLeft,
    .Button svg.IconLeft {
      margin-right: 5px; }
    button svg.IconRight,
    .Button svg.IconRight {
      margin-left: 5px; }
  button--Danger,
  .Button--Danger {
    background-color: #EE0B04; }
    button--Danger:hover,
    .Button--Danger:hover {
      background-color: #bc0903; }
  button--Tertiary,
  .Button--Tertiary {
    background-color: #EE0B04; }
    button--Tertiary:hover,
    .Button--Tertiary:hover {
      background-color: #bc0903; }
  button--Primary,
  .Button--Primary {
    background-color: #007AFF; }
    button--Primary:hover,
    .Button--Primary:hover {
      background-color: #0062cc; }
  button--Cancel,
  .Button--Cancel {
    background-color: transparent;
    color: #007AFF;
    border: 2px solid #007AFF; }
    button--Cancel svg path,
    .Button--Cancel svg path {
      fill: #007AFF; }
    button--Cancel:hover,
    .Button--Cancel:hover {
      color: #0062cc;
      border-color: #0062cc;
      background-color: transparent; }
      button--Cancel:hover svg path,
      .Button--Cancel:hover svg path {
        fill: #0062cc; }
  button--Link,
  .Button--Link {
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 500;
    color: #007AFF; }
    button--Link:hover,
    .Button--Link:hover {
      background-color: transparent;
      color: #0062cc; }
  button--Input,
  .Button--Input {
    background-color: white;
    min-width: 300px;
    border-radius: 30px;
    padding: 15px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #007AFF;
    box-shadow: 0px 0px 10px rgba(16, 91, 170, 0.1); }
    button--Input.Verified, button--Input.Verified:hover,
    .Button--Input.Verified,
    .Button--Input.Verified:hover {
      background-color: #4dcc2c;
      color: white; }
      button--Input.Verified svg, button--Input.Verified:hover svg,
      .Button--Input.Verified svg,
      .Button--Input.Verified:hover svg {
        fill: white; }
    button--Input svg,
    .Button--Input svg {
      height: 24px;
      fill: #007AFF;
      margin-right: 5px; }
    button--Input:hover,
    .Button--Input:hover {
      color: #0062cc;
      background-color: white;
      fill: #0062cc; }
    @media (max-width: 640px) {
      button--Input,
      .Button--Input {
        min-width: inherit; } }
  button .AddItem,
  .Button .AddItem {
    fill: #007AFF;
    position: absolute;
    right: 15px; }

.ButtonGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.MainNav {
  display: flex;
  align-content: center;
  justify-content: space-evenly; }

.Timer {
  display: flex;
  align-content: center; }
  .Timer svg {
    margin: 0;
    margin-top: -4px;
    margin-right: 5px;
    fill: black; }
  .Timer p {
    margin: 0; }

.IconWarning svg {
  fill: #EE0B04; }

.QR svg:not(.IconRight) {
  padding: 25px;
  background-color: white; }

.View {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.Pad {
  padding-left: 15px;
  padding-right: 15px; }

.Label {
  margin: 0;
  margin-bottom: 5px;
  color: #b6b6b6; }

.Danger {
  color: #EE0B04; }

.AltOption {
  display: block;
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 25px 25px;
  margin: 0 auto; }

.FlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .FlexCenter .Form {
    max-width: 600px;
    width: 100%; }

.Truncate {
  width: 65px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 5px; }

.EthLogo {
  width: 36px; }

.Validated {
  display: inline-block;
  color: #4dcc2c;
  text-transform: capitalize; }
  .Validated svg {
    margin-top: -5px;
    fill: #4dcc2c;
    vertical-align: middle; }

.ViewHeight {
  min-height: calc(100vh - 100px); }

.Row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap; }

.Column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column; }
  .Column--50 {
    width: calc(50% - 50px);
    padding: 0px 25px; }
    @media (max-width: 640px) {
      .Column--50 {
        width: 100%; } }

.FlexCenter {
  align-items: center;
  justify-content: center; }

.SmallContainer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto; }

.GridContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }

.Form {
  padding: 15px;
  max-width: 340px;
  margin: 0 auto; }
  .Form .Error {
    color: red;
    font-size: 13px;
    position: relative;
    top: 20px;
    left: 20px;
    margin-bottom: 40px; }

input,
textarea {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: white;
  color: black;
  border: none;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px;
  box-shadow: 0px 0px 10px rgba(16, 91, 170, 0.1); }
  @media (max-width: 640px) {
    input,
    textarea {
      min-width: inherit;
      display: block; } }

.Field {
  position: relative;
  margin-top: 25px; }
  .Field label {
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: 17px;
    color: #3395ff;
    background-color: transparent;
    z-index: 10;
    -webkit-transition: font-size 150ms ease-out, -webkit-transform 150ms ease-out;
    transition: font-size 150ms ease-out, -webkit-transform 150ms ease-out;
    transition: transform 150ms ease-out, font-size 150ms ease-out;
    transition: transform 150ms ease-out, font-size 150ms ease-out, -webkit-transform 150ms ease-out; }
  .Field.HasValue label {
    -webkit-transform: translateY(-75%);
            transform: translateY(-75%);
    font-size: 0.75em; }

.SeedList {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: -15px; }
  .SeedList > * {
    margin: 15px; }

.SeedGrid {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: -15px; }
  .SeedGrid > * {
    margin: 15px; }
  .SeedGrid .FauxInput {
    width: 100px;
    background-color: white;
    border-radius: 50px;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #007AFF;
    box-shadow: 0px 0px 10px rgba(16, 91, 170, 0.1); }

.SeedForm {
  max-width: 800px !important;
  margin: 0 auto; }
  .SeedForm .Field {
    width: 120px;
    display: block;
    margin-right: 70px; }
    .SeedForm .Field input {
      min-width: 120px;
      width: 120px; }

.WordList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 25%;
  margin-right: -15px; }
  .WordList > * {
    margin: 0;
    margin-right: 15px;
    margin-bottom: 15px; }
  .WordList button {
    padding: 10px 20px;
    font-size: 0.85em; }
    .WordList button:disabled {
      display: none; }

.Grid {
  align-items: flex-start; }

.UserWordList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 75%; }

@media (max-width: 640px) {
  .NoMobilePadding {
    padding: 0; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.Loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  display: block;
  z-index: 10; }
  .Loading svg {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 80px;
    height: 80px; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.TopNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto; }
  .TopNav img {
    height: 60px;
    display: inline-block; }
  @media (max-width: 640px) {
    .TopNav {
      background-color: #0a1427; } }

.Brand {
  width: calc(50% - 50px);
  padding: 15px 25px;
  background-color: #0A1427; }
  @media (max-width: 640px) {
    .Brand {
      width: calc(50% - 50px); } }

.TopNav__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  background-color: #0A1427;
  height: 93px;
  padding-right: 30px; }
  .TopNav__actions a {
    margin-left: 50px; }
    .TopNav__actions a svg {
      display: inline;
      margin-right: 5px; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.Header {
  background-color: white;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1em;
  width: calc(100%); }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.WelcomeHero {
  background-color: #0A1427;
  height: 180px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  background-size: 600px;
  background-position: center center;
  background-repeat: no-repeat; }

.WelcomeHero__headline {
  color: white; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.SubNav {
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.035); }
  .SubNav__Inner {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: -2px; }
  .SubNav a {
    color: #0A1427;
    font-size: 1.5em;
    font-weight: 700;
    padding: 25px;
    opacity: 0.25; }
    .SubNav a:hover {
      opacity: 1; }
    .SubNav a.Active {
      opacity: 1;
      border-bottom: 4px solid #0A1427; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.Device {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(16, 91, 170, 0.1);
  min-height: 100px;
  max-width: 600px;
  width: calc(100% - 30px);
  border-radius: 50px;
  padding: 0px 15px;
  margin: 30px auto 0px auto; }
  .Device__Details {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }
    .Device__Details--State {
      margin: 0;
      font-size: 0.85em;
      color: #8b939e;
      text-transform: capitalize; }
      .Device__Details--State.IsConnected::before {
        content: "";
        background-color: #007AFF;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -2px; }
      .Device__Details--State.IsNotConnected::before {
        content: "";
        background-color: #EE0B04;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -2px; }
    .Device__Details h4 {
      font-size: 1.5em;
      margin: 3px 0px; }
    .Device__Details--Address {
      margin: 0;
      font-size: 0.85em;
      color: #8b939e; }
  .Device button {
    background-color: transparent;
    color: #007AFF;
    padding: 0px 15px; }
    .Device button:disabled, .Device button[disabled] {
      opacity: 0.5; }
    .Device button:hover {
      color: #0062cc; }
  @media (max-width: 640px) {
    .Device {
      max-width: 100%; } }
  .Device__Loader {
    padding: 0px 10px; }
    .Device__Loader svg {
      display: block; }

.RemoveDevice svg {
  fill: #007AFF; }

.RemoveDevice:hover svg {
  fill: #0062cc; }

.IsConnecting .Device__Loader {
  display: inline;
  padding: 0px; }

.IsConnecting svg {
  display: inline;
  height: 18px;
  width: 18px;
  vertical-align: middle; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.ModalBackdrop {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  outline: none; }
  .Modal .Close {
    position: absolute;
    top: -32px;
    right: -32px;
    margin: 0;
    padding: 15px;
    background-color: #007AFF;
    cursor: pointer;
    z-index: 1; }
    .Modal .Close svg {
      fill: white;
      width: 36px;
      height: 36px; }
    .Modal .Close:hover {
      background-color: #0062cc; }
  .Modal .Contents {
    position: relative;
    padding: 15px;
    background-color: white;
    border-radius: 20px;
    height: auto;
    overflow: visible;
    min-width: 400px;
    max-width: 400px;
    width: 100%; }
    @media (max-width: 640px) {
      .Modal .Contents {
        min-width: 100%; } }
    .Modal .Contents .ModalActions {
      position: absolute;
      bottom: -75px;
      left: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .Modal .Contents .ModalActions > * {
        width: 100%; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.Home {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.Home__hero {
  background-color: black;
  color: white;
  text-align: center;
  height: 100px; }

.Home__content {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 100px);
  padding-top: 25px;
  padding-bottom: 25px; }
  @media (max-width: 640px) {
    .Home__content {
      width: calc(100% - 50px); } }

ul.react-tabs__tab-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  -webkit-padding-start: 0;
          padding-inline-start: 0; }

li.react-tabs__tab {
  margin: 0; }
  li.react-tabs__tab:hover {
    cursor: pointer; }

li.react-tabs__tab--selected {
  border-bottom: 2px solid black; }

.QrCodeDisplay {
  text-align: center; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }
@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
    pointer-events: none; }
  to {
    opacity: 1;
    top: 0px;
    pointer-events: all; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

@keyframes fadeOut {
  from {
    opacity: 1;
    top: 0px;
    pointer-events: all; }
  to {
    opacity: 0;
    top: -50px;
    pointer-events: none; } }

.FadeIn {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.FadeOut {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; }

.Flash {
  opacity: 0;
  /* make things invisible upon start */
  top: 0px;
  -webkit-animation: fadeIn, fadeOut;
          animation: fadeIn, fadeOut;
  -webkit-animation-fill-mode: forwards, forwards;
          animation-fill-mode: forwards, forwards;
  -webkit-animation-timing-function: ease-in, ease-out;
          animation-timing-function: ease-in, ease-out;
  -webkit-animation-duration: .15s, .15s;
          animation-duration: .15s, .15s;
  -webkit-animation-delay: 0s, 2s;
          animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, 1;
          animation-iteration-count: 1, 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: fixed;
  background-color: #007AFF;
  color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99; }
  .Flash p {
    font-weight: 900; }

.UnAuthHero {
  background-color: #0A1427;
  color: white;
  background-size: 600px;
  background-position: -100px center;
  background-repeat: no-repeat; }
  .UnAuthHero h1 {
    font-size: 3em; }
    @media (max-width: 640px) {
      .UnAuthHero h1 {
        font-size: 1.75em; } }
  .UnAuthHero ul li {
    font-size: 1.5em;
    margin: 15px 0px; }

.Auth__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%; }

.QrCodeScanner {
  text-align: center; }

.ConfirmRecover {
  text-align: center; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


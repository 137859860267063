@import url('https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

$base-font: 'Roboto', sans-serif;
$data-font: 'Share Tech Mono', sans-serif;
$base-font-color: black;
$base-padding: 15px;
$brand: 'japanese-ogre.png';

// Color Palette
$app-background: #FFF;
$app-light: lighten($app-background, 10%);
$app-dark: darken($app-background, 10%);
$primary: #007AFF;
$secondary: #0A1427;
$tertiary: #EE0B04;
$danger: #EE0B04;
$success: rgba(77,204,44,1);
$bright: #00BFFF;
// Hover Palette
$primary-hover: darken($primary, 10%);
$secondary-hover: darken($secondary, 10%);
$tertiary-hover: darken($tertiary, 10%);
$danger-hover: darken($danger, 10%);
$success-hover: darken($success, 10%);

//Media Queries
$grid: 1200px;
$tablet: 768px;
$phone: 640px;

// Other Styles
$shadow: 0px 0px 10px rgba(16,91,170,0.1);

// Animations

@keyframes fadeIn { from { opacity:0; top: -50px; pointer-events: none; } to { opacity:1; top: 0px; pointer-events: all; } }
@keyframes fadeOut { from { opacity:1; top: 0px; pointer-events: all; } to { opacity:0; top: -50px; pointer-events: none; } }

.FadeIn {
    opacity:0;  /* make things invisible upon start */
    top: 0px;
    animation:fadeIn;
    animation-fill-mode:forwards;
    animation-timing-function: ease-in;
    animation-duration: .25s;
    animation-delay: 0s;
    animation-iteration-count: 1;
}

.FadeOut {
    opacity:0;  /* make things invisible upon start */
    top: 0px;
    animation:fadeOut;
    animation-fill-mode:forwards;
    animation-timing-function: ease-out;
    animation-duration: .25s;
    animation-delay: 0s;
    animation-iteration-count: 1;
}

.Flash {
    // animation in/out settings
    opacity:0;  /* make things invisible upon start */
    top: 0px;
    animation:fadeIn, fadeOut;
    animation-fill-mode:forwards, forwards;
    animation-timing-function: ease-in, ease-out;
    animation-duration: .15s, .15s;
    animation-delay: 0s, 2s;
    animation-iteration-count: 1, 1;
    // specific styles
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    background-color: $primary;
    color: white;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    p { font-weight: 900; }
    z-index: 99;
}


@import "../../variables";

.Device {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: $shadow;
  min-height: 100px;
  max-width: 600px;
  width: calc(100% - 30px);
  border-radius: 50px;
  padding: 0px 15px;
  margin: 30px auto 0px auto;
  &__Details {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    &--State {
      margin: 0;
      font-size: 0.85em;
      color: #8b939e;
      text-transform: capitalize;
      &.IsConnected::before {
        content: "";
        background-color: $primary;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -2px;
      }
      &.IsNotConnected::before {
        content: "";
        background-color: $danger;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -2px;
      }
    }
    h4 {
      font-size: 1.5em;
      margin: 3px 0px;
    }
    &--Address {
      margin: 0;
      font-size: 0.85em;
      color: #8b939e;
    }
  }
  button {
    background-color: transparent;
    color: $primary;
    padding: 0px 15px;
    &:disabled,
    &[disabled] {
      opacity: 0.5;
    }
    &:hover {
      color: $primary-hover;
    }
  }
  @media (max-width: $phone) {
    max-width: 100%;
  }
  &__Loader {
    padding: 0px 10px;
    svg {
      display: block;
    }
  }
}

.RemoveDevice {
  svg {
    fill: $primary;
  }
  &:hover svg {
    fill: $primary-hover;
  }
}

.IsConnecting {
  .Device__Loader {
    display: inline;
    padding: 0px;
  }
  svg {
    display: inline;
    height: 18px;
    width: 18px;
    vertical-align: middle;
  }
}

@import "../../variables";

.ModalBackdrop {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  outline: none;
  .Close {
    position: absolute;
    top: -32px;
    right: -32px;
    margin: 0;
    padding: 15px;
    background-color: $primary;
    cursor: pointer;
    svg {
      fill: white;
      width: 36px;
      height: 36px;
    }
    &:hover {
      background-color: $primary-hover;
    }
    z-index: 1;
  }
  .Contents {
    position: relative;
    padding: $base-padding;
    background-color: $app-light;
    border-radius: 20px;
    height: auto;
    overflow: visible;
    min-width: 400px;
    max-width: 400px;
    width: 100%;
    @media (max-width: $phone) {
      min-width: 100%;
    }
    .ModalActions {
      position: absolute;
      bottom: -75px;
      left: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      > * {
        width: 100%;
      }
    }
  }
}

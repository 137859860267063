@import '../../variables';

.WelcomeHero {
  background-color: $secondary;
  height: 180px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  // Background Image
  background-size: 600px;
  background-position: center center;
  background-repeat: no-repeat;
}

.WelcomeHero__headline {
  color: white;
}

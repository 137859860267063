@import "./variables";

/* Commented out for testing
.Brand {
  background-image: url('/assets/' + $brand);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
  height: 60px;
  width: 180px;
  display: block;
}
*/

body {
  background-color: $app-background;
  color: $base-font-color;
}

// Typography

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $base-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
}

.Data {
  font-family: $data-font;
  font-weight: 400;
  word-break: break-all;
  margin: 0;
}

.Bold {
  font-weight: 900;
}

// Links and Buttons
a {
  color: $primary;
  fill: $primary;
  text-decoration: none;
  transition: all 0.15s linear;
  vertical-align: middle;
  cursor: pointer;
  svg {
    display: inline-block;
    margin: 0;
    padding: 0px;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-top: -3px;
    &.IconLeft {
      margin-right: 5px;
    }
    &.IconRight {
      margin-left: 5px;
    }
  }
  &:hover {
    color: $primary-hover;
    fill: $primary-hover;
  }
}

button,
.Button {
  // Reset
  appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  // Style
  transition: all 0.15s ease-in-out;
  background-color: $primary;
  color: white;
  border-radius: 50px;
  padding: 15px 30px;
  max-width: 100%;
  display: block;
  margin: 15px 0px;
  font-size: 16px;
  text-align: center;
  font-weight: 900;
  &:hover {
    background-color: $primary-hover;
    color: white;
    fill: white;
  }
  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }
  &.Back {
    margin: 0;
    padding-top: 20px;
    padding-left: 0px;
    margin-left: 0px;
    left: 0;
    text-align: left;
    background-color: transparent;
    color: black !important;
    svg {
      fill: black !important;
    }
    border-radius: 0px;
    width: auto;
    &:hover {
      margin-left: -5px;
    }
  }
  svg {
    display: inline-block;
    margin: 0;
    padding: 0px;
    vertical-align: middle;
    fill: white;
    &.IconLeft {
      margin-right: 5px;
    }
    &.IconRight {
      margin-left: 5px;
    }
  }
  &--Danger {
    background-color: $danger;
    &:hover {
      background-color: $danger-hover;
    }
  }
  &--Tertiary {
    background-color: $tertiary;
    &:hover {
      background-color: $tertiary-hover;
    }
  }
  &--Primary {
    background-color: $primary;
    &:hover {
      background-color: $primary-hover;
    }
  }
  &--Cancel {
    background-color: transparent;
    color: $primary;
    border: 2px solid $primary;
    svg path {
      fill: $primary;
    }
    &:hover {
      color: $primary-hover;
      border-color: $primary-hover;
      background-color: transparent;
      svg path {
        fill: $primary-hover;
      }
    }
  }
  &--Link {
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 500;
    color: $primary;
    &:hover {
      background-color: transparent;
      color: $primary-hover;
    }
  }
  &--Input {
    background-color: $app-light;
    min-width: 300px;
    border-radius: 30px;
    padding: 15px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $primary;
    box-shadow: $shadow;
    &.Verified,
    &.Verified:hover {
      background-color: $success;
      color: white;
      svg {
        fill: $app-light;
      }
    }
    svg {
      height: 24px;
      fill: $primary;
      margin-right: 5px;
    }
    &:hover {
      color: $primary-hover;
      background-color: $app-light;
      fill: $primary-hover;
    }
    @media (max-width: $phone) {
      min-width: inherit;
    }
  }
  .AddItem {
    fill: $primary;
    position: absolute;
    right: 15px;
  }
}

.ButtonGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

// Components

.MainNav {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
}

.Timer {
  display: flex;
  align-content: center;
  svg {
    margin: 0;
    margin-top: -4px;
    margin-right: 5px;
    fill: $base-font-color;
  }
  p {
    margin: 0;
  }
}

.IconWarning svg {
  fill: $danger;
}

.QR svg:not(.IconRight) {
  padding: 25px;
  background-color: white;
}

// Utils

.View {
  padding: $base-padding;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Pad {
  padding-left: $base-padding;
  padding-right: $base-padding;
}

.Label {
  margin: 0;
  margin-bottom: 5px;
  color: rgb(182, 182, 182);
}

.Danger {
  color: $danger;
}

.AltOption {
  display: block;
  text-align: center;
  width: max-content;
  padding: 25px 25px;
  margin: 0 auto;
}

.FlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .Form {
    max-width: 600px;
    width: 100%;
  }
}

.Truncate {
  width: 65px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 5px;
}

.EthLogo {
  width: 36px;
}

.Validated {
  display: inline-block;
  color: $success;
  text-transform: capitalize;
  svg {
    margin-top: -5px;
    fill: $success;
    vertical-align: middle;
  }
}

// Layout
.ViewHeight {
  min-height: calc(100vh - 100px);
}

.Row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.Column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  &--50 {
    width: calc(50% - 50px);
    padding: 0px 25px;
    @media (max-width: $phone) {
      width: 100%;
    }
  }
}

.FlexCenter {
  align-items: center;
  justify-content: center;
}

.SmallContainer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.GridContainer {
  width: 100%;
  max-width: $grid;
  margin: 0 auto;
}

// Forms
.Form {
  padding: $base-padding;
  max-width: 340px;
  margin: 0 auto;
  .Error {
    color: red;
    font-size: 13px;
    position: relative;
    top: 20px;
    left: 20px;
    margin-bottom: 40px;
  }
}

input,
textarea {
  // Reset
  outline: none;
  appearance: none;
  border: none;
  background-color: $app-light;
  color: black;
  // Style
  border: none;
  min-width: 300px;
  max-width: 100%;
  font-size: 21px;
  padding: 20px 20px 10px 20px;
  border-radius: 35px;
  box-shadow: $shadow;
  @media (max-width: $phone) {
    min-width: inherit;
    display: block;
  }
}

.Field {
  position: relative;
  margin-top: 25px;
  label {
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: 17px;
    color: lighten($primary, 10%);
    background-color: transparent;
    z-index: 10;
    transition: transform 150ms ease-out, font-size 150ms ease-out;
  }
  &.HasValue {
    label {
      transform: translateY(-75%);
      font-size: 0.75em;
    }
  }
}

.SeedList {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: -15px;
  > * {
    margin: 15px;
  }
}

.SeedGrid {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: -15px;
  > * {
    margin: 15px;
  }
  .FauxInput {
    width: 100px;
    background-color: $app-light;
    border-radius: 50px;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $primary;
    box-shadow: $shadow;
  }
}

.SeedForm {
  max-width: 800px !important;
  margin: 0 auto;
  .Field {
    width: 120px;
    display: block;
    margin-right: 70px;
    input {
      min-width: 120px;
      width: 120px;
    }
  }
}

.WordList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 25%;
  margin-right: -15px;
  > * {
    margin: 0;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  button {
    padding: 10px 20px;
    font-size: 0.85em;
    &:disabled {
      display: none;
    }
  }
}

.Grid {
  align-items: flex-start;
}

.UserWordList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 75%;
}

@media (max-width: $phone) {
  .NoMobilePadding {
    padding: 0;
  }
}

@import '../../variables';

.Header {
    background-color: $app-light;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
    width: calc(100%);
}
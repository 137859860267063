@import '../../variables';

.SubNav {
  width: 100%;
  border-bottom: 2px solid rgba(0,0,0,0.035);
  &__Inner {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: -2px;
  }
  a { 
    color: $secondary; 
    font-size: 1.5em; 
    font-weight: 700;
    padding: 25px;
    opacity: 0.25;
    &:hover { opacity: 1; }
    &.Active {
      opacity: 1;
      border-bottom: 4px solid $secondary;
    }
  }
}

@import '../../variables';

.Loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    background: $app-light;
    display: block;
    z-index: 10;
    svg {  
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
    }
}
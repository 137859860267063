@import "../../variables";

.Home {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Home__hero {
  background-color: black;
  color: white;
  text-align: center;
  height: 100px;
}

.Home__content {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 100px);
  padding-top: 25px;
  padding-bottom: 25px;
  @media (max-width: $phone) {
    width: calc(100% - 50px);
  }
}

ul.react-tabs__tab-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-inline-start: 0;
}

li.react-tabs__tab {
  margin: 0;
  &:hover {
    cursor: pointer;
  }
}

li.react-tabs__tab--selected {
  border-bottom: 2px solid black;
}
